import * as React from 'react'
import { graphql } from 'gatsby'
import { BlockTitle, Container } from 'components/common/styles'
import styled from '@emotion/styled'
import Sidebar from 'components/pages/faq/sidebar'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Layout from 'layouts/default'
import { between } from 'polished'
import { Document } from '@contentful/rich-text-types'
import Question from '../components/pages/faq/question'
import { createFAQSchema } from '../utils/richSnippets'
import { createTextBody } from '../utils/contentful'
import richSnippet from '../components/common/rich-snippet'

type Props = {
  data: {
    allContentfulFaq: {
      edges: [
        {
          node: {
            questions: [
              {
                updatedAt: string
                question: string
                answer: {
                  json: Document
                }
              }
            ]
            title: string
            slug: string
          }
        }
      ]
    }
  },
  location: string
  slug: string
};

const AdaptiveContainer = styled(Container)`
  max-width: 1280px;
`

const LocalContainer = styled(Container)`
  margin-top: 26px;
  margin-bottom: 90px;
  
  flex-direction: row;
  justify-content: space-between;
  
  max-width: 1280px;
  
  // max-width: 1280px;
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const GlobalTitle = styled(BlockTitle)`
  font-size: 40px;
  line-height: 1.2;
  
  margin-top: 55px;
`

const Content = styled.div`
  flex: 1;
  padding-left: 100px;
  
  @media screen and (max-width: 1300px) {
    padding-left: ${between('50px', '150px', '768px', '1300px')};
  }
  
  @media screen and (max-width: 768px) {
    padding-left: 0;
  }
  
  h2 {
    font-size: 24px;
    line-height: 1.5;
    color: #000;
    font-weight: bold;
    margin-bottom: .5em;
  }
  
  h3 {
    font-size: 20px;
    line-height: 1.5;
    color: #000;
    font-weight: bold;
    margin-bottom: .5em;
  }
  
  b {
    font-weight: bold;
  }
  
  p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 0.3em;
  }
  
  p + h2 {
    margin-top: 30px;
  }
  
  p + h3 {
    margin-top: 15px;
  }
  
  a {
    color: ${props => props.theme.colors.green100};
    font-weight: 500;
  }
  
  ul {
    margin-left: 2em;
    list-style: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    li {
      &:not(:last-of-type) {
        margin-bottom: 0.3em;
      }
    }
  }
  
  ol {
    margin-left: 2em;
    list-style: decimal;
    li {
      &:not(:last-of-type) {
        margin-bottom: 0.3em;
      }
    }
    margin-bottom: 1em;
  }
`

const FAQPage: React.FC<Props> = ({ location, data }) => {
  const page = data.allContentfulFaq.edges[0].node
  const schema = createFAQSchema(
    page.questions.map(
      i => ({
        question: i.question,
        answer: createTextBody(i.answer.json),
        publishingDate: i.updatedAt,
      }),
    ),
  )
  React.useEffect(() => {
    window.analytics.page('FAQ', page.title)
  }, [])
  return (
    <Layout location={location} title={`${page.title} | FAQ | ComCard`} helmetChildren={richSnippet(schema)}>
      <AdaptiveContainer>
        <GlobalTitle>Frequently Asked Questions</GlobalTitle>
      </AdaptiveContainer>
      <LocalContainer>
        <Sidebar activeItem={page.slug} />
        <Content>
          {page.questions.map(q => (
            <Question question={q.question} answer={documentToReactComponents(q.answer.json)} />
          ))}
        </Content>
      </LocalContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
    query FAQPage($slug: String!){
        allContentfulFaq(filter: {slug: {eq: $slug}}) {
            edges {
                node {
                    slug
                    title
                    questions {
                        updatedAt
                        question
                        answer {
                            json
                        }
                    }
                }
            }
        }
    }
`

export default FAQPage

import * as React from 'react'
import styled from '@emotion/styled'
import { graphql, Link, StaticQuery } from 'gatsby'

type Props = {
  activeItem: string
  data?: {
    allContentfulFaq: {
      edges: [
        {
          node: {
            slug: string
            title: string
          }
        }
      ]
    }
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`

type ItemProps = {
  isActive: boolean
}

const Item = styled(Link)<ItemProps>`
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  text-decoration: none;
  color: ${props => props.isActive ? props.theme.colors.green100 : '#000'};
`

const Sidebar: React.FC<Props> = ({ activeItem, data }) => {
  return (
    <Container>
      <StaticQuery
        query={graphql`
query {
    allContentfulFaq(filter: {slug: {ne: "general-questions"}}) {
        edges {
            node {
                slug
                title
            }
        }
    }
}
    `}
        render={data => (
          <>
            <Item to={`/faq`} isActive={activeItem === 'general-questions'}>General Questions</Item>
            {
              data.allContentfulFaq.edges.map(i=>(
                <Item to={`/faq/${i.node.slug}`} isActive={activeItem === i.node.slug}>{i.node.title}</Item>
              ))
            }
          </>
        )}
      />

    </Container>
  )
}

export default Sidebar

import * as React from 'react'
import styled from '@emotion/styled'

import PlusSVG from 'assets/images/icons/plus.svg'
import MinusSVG from 'assets/images/icons/minus.svg'

type Props = {
  question: string
  answer: React.ReactNode
};

type ContainerProps = {
  isOpen: boolean
}

const Container = styled.div<ContainerProps>`
  border-bottom: ${props => props.isOpen ? '1px solid #F9F8F7' : '1px solid #DADADA'};
  transition: all ease-in-out 50ms;
`
const AlwaysOn = styled.div`
  display: flex;
  flex-direction: row;
  padding: 30px 0;
  cursor: pointer;
`
const IconContainer = styled.div`
  font-size: 30px;
  color: ${props => props.theme.colors.green100};
  font-weight: 600;
  
  width: 35px;
  flex-shrink: 0;
`


const QuestionContainer = styled.div`
  font-size: 20px;
  line-height: 1.4;
  font-weight: bold;
  color: #000;
  padding-top: 3px;
`

type AnswerProps = {
  isOpen: boolean
}

const Answer = styled.div<AnswerProps>`
  padding-left: 35px;
  font-size: 16px;
  line-height: 1.5;
  color: #666666;
  
  display: ${props => props.isOpen ? 'block' : 'none'};
`

const Question: React.FC<Props> = props => {
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <Container isOpen={isOpen}>
      <AlwaysOn onClick={() => setIsOpen(!isOpen)}>
        <IconContainer>
          {isOpen ? <img src={MinusSVG} /> : <img src={PlusSVG} />}
        </IconContainer>
        <QuestionContainer>{props.question}</QuestionContainer>
      </AlwaysOn>
      <Answer isOpen={isOpen}>{props.answer}</Answer>
    </Container>
  )
}

export default Question
